import { CircularProgress, Container, Grid } from "@mui/material";
import axios from "axios";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import Swal from "sweetalert2";

const EmailConfirm = () => {
  const { token } = useParams() || "";
  const { REACT_APP_API_URL } = process.env;
  let history = useHistory();

  useEffect(() => {
    axios
      .post(`${REACT_APP_API_URL}/email-verify`, { token: token })
      .then((result) => {
        if (result.status === 200) {
          Swal.fire({
            icon: "success",
            showConfirmButton: false,
            title: "Email verified successfully",
            timer: 2000,
            timerProgressBar: true,
          });
          setTimeout(function () {
            history.replace("/");
          }, 3000);
        } else {
          alert(result);
        }
      })
      .catch((error) => {
        if (error.response) {
          // console.log(error.response.data);
          // console.log(error.response.data.message);
          // console.log(error.response.status);
          // console.log(error.response.headers);
          if (error.response.status === 422) {
            Object.values(error.response.data.message).map((a) => {
              return Swal.fire({
                icon: "error",
                showConfirmButton: false,
                // title: a[0],
                // html: "I will close in <b></b> milliseconds.",
                html: a[0],
                timer: 2000,
                timerProgressBar: true,
              });
            });
          } else {
            Swal.fire({
              icon: "error",
              showConfirmButton: false,
              // title: a[0],
              // html: "I will close in <b></b> milliseconds.",
              html: error.response.data.message,
              timer: 2000,
              timerProgressBar: true,
            });
          }
        }
      });
  }, [token]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ textAlign: "center", marginTop: "40vh" }}>
          <Container>
            <CircularProgress color="primary" size={60} />
          </Container>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "center", margin: "15px" }}>
          <h2>Please wait while we verify your email and activate your account...</h2>
        </Grid>
      </Grid>
    </>
  );
};

export default EmailConfirm;
