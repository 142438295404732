import { createSlice, configureStore } from "@reduxjs/toolkit";

const initialState = { user: "", isAuthenticated: false };

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login(state, action) {
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    logout(state) {
      state.user = "";
      state.isAuthenticated = false;
    },
  },
});

const store = configureStore({ reducer: { user: userSlice.reducer } });

export const userActions = userSlice.actions;

export default store;
